import { useTranslate } from "@tolgee/react";
import { memo } from "react";
import { Link } from "react-router-dom";

import { EventResponse } from "@/shared/api/general.api";
import { useEventType } from "@/shared/lib/hooks";
import { cn, formatDate } from "@/shared/lib/utils";
import { Button } from "@/shared/ui";

import { EventModalProps, EventModalTab, Status } from "../model";
import { Figure } from "./figure";

const getCoordinates = (latitude?: string, longitude?: string) => {
    if (!latitude || !longitude) return "-";
    return `${latitude}, ${longitude}`;
};

export const EventCard: React.FC<
    EventResponse & { toggleOpen?: (modal: EventModalProps) => void }
> = memo(
    ({
        id,
        type,
        photo,
        video,
        status,
        client,
        created_at,
        received_at,
        speed,
        vehicle,
        driver,
        device,
        latitude,
        longitude,
        org_struct_lvl1,
        org_struct_lvl2,
        toggleOpen,
    }) => {
        const { t } = useTranslate();
        const { getEventTypeBySlug } = useEventType();
        const eventName = getEventTypeBySlug(type).name;
        const verificationStatus = Status[status];
        const organization = org_struct_lvl2?.name ?? org_struct_lvl1?.name;

        const onModalOpen = (tab: EventModalTab) => () =>
            toggleOpen?.({
                id,
                tab,
            });

        return (
            <>
                <div className="flex flex-col space-y-4 justify-between border border-gray-200 rounded-xl p-2.5 hover:shadow-md transition-shadow">
                    <div className="flex flex-col space-y-4">
                        <div
                            className="relative w-full h-[198px] rounded-lg overflow-hidden cursor-pointer"
                            onClick={onModalOpen(EventModalTab.Photo)}
                        >
                            {/* TODO: move to Image component */}
                            <img
                                src={
                                    photo ??
                                    `https://placehold.co/640x480?text=${t("event-photo-not-found")}`
                                }
                                loading="lazy"
                                onError={(e) => {
                                    e.currentTarget.src = `https://via.placeholder.com/240x198?text=${t("event-photo-not-found")}`;
                                }}
                                className="w-full h-full object-cover"
                                alt="device photo"
                            />
                            {verificationStatus && (
                                <div
                                    className={cn(
                                        verificationStatus.color,
                                        "absolute bottom-2.5 left-2.5 bg-current text-sm py-1 px-4 rounded-lg",
                                    )}
                                >
                                    <span
                                        className={cn("text-white", {
                                            "text-black": status === 1,
                                        })}
                                    >
                                        {verificationStatus.name}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="text-lg font-semibold">
                            {client?.name}
                            <div className="text-sm text-nowrap text-ellipsis overflow-hidden h-5">
                                {organization}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-2">
                            <Figure
                                label={t("received_at_date")}
                                content={formatDate(new Date(received_at))}
                            />
                            <Figure
                                label={t("created_at_date")}
                                content={formatDate(new Date(created_at))}
                            />
                        </div>
                        <div className="flex flex-col space-y-1 items-baseline">
                            <h3>{eventName}</h3>
                            <span className="font-bold py-2 px-2.5 bg-accent rounded-lg">
                                {speed}&nbsp;{t("km_h")}
                            </span>
                        </div>
                        <div className="grid grid-cols-2 gap-x-2">
                            <Figure
                                label="ID"
                                content={id}
                                // as={Link}
                                // to={`/events/${id}`}
                                className="text-primary hover:underline cursor-pointer"
                                onClick={onModalOpen(EventModalTab.Photo)}
                            />
                            <Figure
                                label={t("side_number")}
                                content={vehicle?.side_number}
                                as={Link}
                                to={`/vehicles/${vehicle?.id}`}
                                className="text-primary hover:underline"
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-x-2">
                            <Figure
                                label={t("driver")}
                                content={driver?.name}
                                as={Link}
                                to={`/drivers/${driver?.id}`}
                                className="text-primary hover:underline"
                            />
                            <Figure
                                label={t("device")}
                                content={device?.name}
                                as={Link}
                                to={`/devices/${device?.id}`}
                                className="text-primary hover:underline"
                            />
                        </div>
                        <Figure
                            label={t("coordinates")}
                            content={getCoordinates(latitude, longitude)}
                        />
                    </div>
                    <div className="grid grid-flow-col gap-x-2.5 p-1.5">
                        <Button
                            variant="outline"
                            onClick={onModalOpen(EventModalTab.Photo)}
                        >
                            {t("button.photo")}
                        </Button>
                        {video.length > 0 && (
                            <Button
                                variant="outline"
                                onClick={onModalOpen(EventModalTab.Video)}
                            >
                                {t("button.video")}
                            </Button>
                        )}
                    </div>
                </div>
            </>
        );
    },
);

EventCard.displayName = "EventCard";
