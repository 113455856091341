import { useTranslate } from "@tolgee/react";
import L from "leaflet";
import "leaflet-polylinedecorator";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";

import { formatDate } from "@/shared/lib/utils";

import { alertIcon, getColor, getDistance } from "../lib";
import { IGpsData } from "../model";
import { Legend } from "./legend";
import { Player } from "./player";

/**
 * Хук для создания HTML-контента всплывающих подсказок
 * Возвращает функцию, которая генерирует HTML с датой и скоростью
 */
const useContent = () => {
    const { t } = useTranslate();

    return (dtime: Date, speed?: number) => {
        return `
            <div>${formatDate(dtime)}</div>
            <div>${speed} ${t("km_h")}</div>
        `;
    };
};

const MIN_DISTANCE = 2; // Минимальное расстояние между точками для отображения линий
const WEIGHT = 2; // Базовая ширина линии трека

/**
 * Создает декоратор для линии трека
 * @param distance - расстояние между точками
 * @param color - цвет линии
 * @param polyline - линия, к которой применяется декоратор
 * @param map - карта
 * @returns объект декоратора
 */
const createDecorator = (
    distance: number,
    color: string,
    polyline: L.Polyline,
    map: L.Map,
) => {
    const patterns =
        distance > MIN_DISTANCE
            ? [
                  {
                      offset: 0,
                      repeat: 15,
                      symbol: L.Symbol.dash({
                          pixelSize: 9,
                          pathOptions: {
                              color: "black",
                              weight: WEIGHT + 2,
                          },
                      }),
                  },
                  {
                      offset: 0,
                      repeat: 15,
                      symbol: L.Symbol.dash({
                          pixelSize: 7,
                          pathOptions: {
                              color: "gray",
                              weight: WEIGHT,
                          },
                      }),
                  },
              ]
            : [
                  {
                      offset: "100%",
                      repeat: 0,
                      symbol: L.Symbol.arrowHead({
                          pixelSize: 9,
                          polygon: false,
                          pathOptions: {
                              color: "black",
                              stroke: true,
                              weight: WEIGHT + 2,
                          },
                      }),
                  },
                  {
                      offset: "100%",
                      repeat: 0,
                      symbol: L.Symbol.arrowHead({
                          pixelSize: 7,
                          polygon: false,
                          pathOptions: {
                              color,
                              stroke: true,
                              weight: WEIGHT,
                          },
                      }),
                  },
              ];

    return L.polylineDecorator(polyline, { patterns }).addTo(map);
};

/**
 * Создает сегмент трека между двумя точками
 * @param point1 - начальная точка сегмента
 * @param point2 - конечная точка сегмента с дополнительными данными
 * @param map - карта
 * @param getContent - функция для создания контента всплывающей подсказки
 * @returns объект с элементами сегмента (подложка, линия, декоратор)
 */
const createTrackSegment = (
    point1: { lat: number; lng: number },
    point2: { lat: number; lng: number; timeIso: number; speed: number },
    map: L.Map,
    getContent: (dtime: Date, speed?: number) => string,
) => {
    const roundedSpeed = Math.round(point2.speed / 25) * 25;
    const color = getColor(roundedSpeed);
    const distance = getDistance(
        point1.lat,
        point1.lng,
        point2.lat,
        point2.lng,
    );
    const coords: L.LatLngExpression[] = [
        [point1.lat, point1.lng],
        [point2.lat, point2.lng],
    ];

    // Создаем черную подложку
    const base = L.polyline(coords, {
        color: "black",
        opacity: distance < MIN_DISTANCE ? 1 : 0,
        weight: WEIGHT + 2,
    }).addTo(map);

    // Создаем основную линию
    const polyline = L.polyline(coords, {
        color,
        opacity: distance < MIN_DISTANCE ? 1 : 0,
        weight: WEIGHT,
    }).addTo(map);

    polyline.bindTooltip(getContent(new Date(point2.timeIso), point2.speed));

    const decorator = createDecorator(distance, color, polyline, map);

    return { base, polyline, decorator };
};

/**
 * Компонент для отображения трека на карте
 * @param data - данные трека и событий
 * @param withPlayer - флаг для отображения плеера
 */
export const Track: React.FC<IGpsData & { withPlayer?: boolean }> = ({
    data,
    withPlayer = false,
}) => {
    const map = useMap();
    const getContent = useContent();
    const { track, events } = data;

    // Вычисляем границы трека для автоматического масштабирования карты
    const allCoordinates = useMemo(
        () => L.latLngBounds(track.map((point) => [point.lat, point.lng])),
        [track],
    );

    // Эффект для отрисовки трека и событий на карте
    useEffect(() => {
        const markers: L.Marker[] = [];
        const trackElements: {
            base: L.Polyline;
            polyline: L.Polyline;
            decorator: L.PolylineDecorator;
        }[] = [];

        // Отображение событий на карте
        events.forEach((event) => {
            const marker = L.marker([+event.latitude, +event.longitude], {
                icon: alertIcon,
            }).addTo(map);

            marker.bindPopup(`
                <div class="font-bold mb-2">${event.type_name}</div>
                ${getContent(new Date(event.dtime), event.speed)}
            `);

            markers.push(marker);
        });

        // Создание трека
        for (let i = 0; i < track.length - 1; i++) {
            const elements = createTrackSegment(
                track[i],
                track[i + 1],
                map,
                getContent,
            );
            trackElements.push(elements);
        }

        map.fitBounds(allCoordinates, { padding: [50, 50] });

        // Очистка ресурсов при размонтировании компонента
        // Важно удалить все элементы с карты, чтобы избежать утечек памяти
        return () => {
            markers.forEach((marker) => marker.remove());
            trackElements.forEach(({ base, polyline, decorator }) => {
                base.remove();
                polyline.remove();
                decorator.remove();
            });
        };
    }, [allCoordinates, events, getContent, map, track]);

    if (!withPlayer) return null;

    return (
        <>
            <Player data={track} />
            <Legend />
        </>
    );
};
