// hook for filters
import qs from "qs";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type FilterRecord = Record<string, unknown>;

const processObject = (data: FilterRecord): FilterRecord => {
    const result: FilterRecord = {};

    for (const [key, value] of Object.entries(data)) {
        if (!value) continue;

        if (typeof value === "object" && !Array.isArray(value)) {
            const processed = processObject(value as FilterRecord);
            if (Object.keys(processed).length > 0) {
                result[key] = processed;
            }
            continue;
        }

        if (Array.isArray(value)) {
            result[key] = value;
            continue;
        }

        if (!Number.isNaN(+value)) {
            result[key] = +value;
            continue;
        }

        result[key] = value;
    }

    return result;
};

const STRINGIFY_OPTIONS: qs.IStringifyOptions = {
    arrayFormat: "brackets",
    encodeValuesOnly: true,
    skipNulls: true,
};

const PARSE_OPTIONS: qs.IParseOptions = {};

export const useFilter = <T extends FilterRecord>(defaultValue?: T) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (defaultValue && !searchParams.size) {
            setSearchParams(qs.stringify(defaultValue, STRINGIFY_OPTIONS), {
                replace: true,
            });
        }
        // eslint-disable-next-line
    }, []);

    const filter = useMemo(() => {
        const queryDataFromUrl = qs.parse(
            searchParams.toString(),
            PARSE_OPTIONS,
        );

        return processObject(queryDataFromUrl) as T;
    }, [searchParams]);

    const setFilter = useCallback(
        (newFilter?: T) => {
            setSearchParams(qs.stringify(newFilter, STRINGIFY_OPTIONS), {
                replace: true,
            });
        },
        [setSearchParams],
    );

    return { filter, setFilter };
};
