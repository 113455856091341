import * as Sentry from "@sentry/react";
import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import { Archive, GPS, History } from "@/pages/device";

import { Route } from "@/shared/config";

const AppLayout = lazy(() => import("@/pages/app-layout"));
const LoginLayout = lazy(() => import("@/pages/login-layout"));
const Error = lazy(() => import("src/pages/error"));
const Login = lazy(() => import("@/pages/login"));
const ForgotPassword = lazy(() => import("@/pages/forgot-password"));
const ResetPassword = lazy(() => import("@/pages/reset-password"));
const Home = lazy(() => import("@/pages/home"));
const Vehicles = lazy(() => import("@/pages/vehicles"));
const Vehicle = lazy(() => import("@/pages/vehicle"));
const VehicleActivity = lazy(() => import("@/pages/vehicle/activity"));
const VehicleHistory = lazy(() => import("@/pages/vehicle/history"));
const Devices = lazy(() => import("@/pages/devices"));
const Device = lazy(() => import("@/pages/device"));
const Events = lazy(() => import("@/pages/events"));
const Drivers = lazy(() => import("@/pages/drivers"));
const Driver = lazy(() => import("@/pages/driver"));
const DriverHistory = lazy(() => import("@/pages/driver/history"));
const Settings = lazy(() => import("@/pages/settings"));
const SettingsPersonal = lazy(() => import("@/pages/settings/personal"));
const SettingsPassword = lazy(() => import("@/pages/settings/password"));
const DeviceTimeControl = lazy(
    () => import("@/pages/reports/device-time-control"),
);
const DriverStatisticReport = lazy(
    () => import("@/pages/reports/driver-statistic"),
);
const IndividualAnalyticalReport = lazy(
    () => import("@/pages/reports/individual-analytical-report"),
);

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    {
        path: Route.App,
        element: <AppLayout />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: Route.Vehicles,
                children: [
                    {
                        index: true,
                        element: <Vehicles />,
                    },
                    {
                        path: Route.Vehicle,
                        element: <Vehicle />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="activity" replace />,
                            },
                            {
                                path: "activity",
                                element: <VehicleActivity />,
                            },
                            {
                                path: "history",
                                element: <VehicleHistory />,
                            },
                        ],
                    },
                ],
            },
            {
                path: Route.Devices,
                children: [
                    {
                        index: true,
                        element: <Devices />,
                    },
                    {
                        path: Route.Device,
                        element: <Device />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="history" replace />,
                            },
                            {
                                path: "history",
                                element: <History />,
                            },
                            {
                                path: "gps",
                                element: <GPS />,
                            },
                            {
                                path: "archive",
                                element: <Archive />,
                            },
                        ],
                    },
                ],
            },
            {
                path: Route.Events,
                element: <Events />,
            },
            {
                path: Route.Drivers,
                children: [
                    {
                        index: true,
                        element: <Drivers />,
                    },
                    {
                        path: Route.Driver,
                        element: <Driver />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to="history" replace />,
                            },
                            {
                                path: "history",
                                element: <DriverHistory />,
                            },
                            {
                                path: "medical-checkups",
                                element: <>Medical Checkups</>,
                            },
                        ],
                    },
                ],
            },
            {
                path: Route.Reports,
                children: [
                    {
                        path: Route.DeviceTimeControl,
                        element: <DeviceTimeControl />,
                    },
                    {
                        path: Route.DriverStatisticReport,
                        element: <DriverStatisticReport />,
                    },
                    {
                        path: Route.IndividualAnalyticalReport,
                        element: <IndividualAnalyticalReport />,
                    },
                ],
            },
            {
                path: Route.UserSettings,
                element: <Settings />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="personal" replace />,
                    },
                    {
                        path: "personal",
                        element: <SettingsPersonal />,
                    },
                    {
                        path: "password",
                        element: <SettingsPassword />,
                    },
                ],
            },
            {
                path: Route.UserReports,
                element: <>User Reports</>,
            },
            {
                path: Route.UserFilters,
                element: <>User Filters</>,
            },
        ],
    },
    {
        path: Route.Login,
        element: <LoginLayout />,
        children: [
            {
                index: true,
                element: <Login />,
            },
            {
                path: Route.ForgotPassword,
                element: <ForgotPassword />,
            },
            {
                path: Route.ResetPassword,
                element: <ResetPassword />,
            },
        ],
    },
    {
        path: "403",
        element: <Error />,
    },
    {
        path: "404",
        element: <Error />,
    },
    {
        path: "500",
        element: <Error />,
    },
    {
        path: Route.NotFound,
        element: <Navigate to="404" replace />,
    },
]);
