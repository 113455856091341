import { createColumnHelper } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";
import { Image, Video } from "lucide-react";
import { Link } from "react-router-dom";

import { EventModalProps, EventModalTab, Status } from "@/features/event";

import { EventResponse } from "@/shared/api/general.api";
import { useEventType } from "@/shared/lib/hooks";
import { cn, formatDate } from "@/shared/lib/utils";

const columnHelper = createColumnHelper<EventResponse>();

export const useColumns = ({
    toggleOpen,
}: {
    toggleOpen?: (modal: EventModalProps) => void;
}) => {
    const { t } = useTranslate();
    const { getEventTypeBySlug } = useEventType();

    return [
        columnHelper.accessor("id", {
            header: "ID",
        }),
        columnHelper.accessor("created_at", {
            header: t("created_at_date"),
            cell: ({ getValue }) => {
                const value = getValue();
                if (!value) return null;
                return (
                    <>
                        <span>{formatDate(new Date(value), "PP")}</span>
                        <span>{formatDate(new Date(value), "pp")}</span>
                    </>
                );
            },
        }),
        columnHelper.accessor("received_at", {
            header: t("received_at_date"),
            cell: ({ getValue }) => {
                const value = getValue();
                if (!value) return null;
                return (
                    <>
                        <span>{formatDate(new Date(value), "PP")}</span>
                        <span>{formatDate(new Date(value), "pp")}</span>
                    </>
                );
            },
        }),
        columnHelper.accessor("type", {
            header: t("events.title"),
            cell: ({ getValue, row }) => {
                const value = getValue();
                const eventName = getEventTypeBySlug(value).name;

                return (
                    <div
                        className={cn(
                            Status[row.original.status]?.color,
                            {
                                "text-black": row.original.status === 1,
                            },
                            "border border-current py-1.5 px-2 rounded-lg",
                        )}
                    >
                        <span>{eventName}</span>
                    </div>
                );
            },
        }),
        columnHelper.accessor("device", {
            id: "device",
            header: t("device"),
            cell: ({ getValue }) => {
                const value = getValue();
                if (!value) return null;
                return (
                    <Link
                        to={`/devices/${value.id}`}
                        className="text-primary hover:underline"
                    >
                        {value.name}
                    </Link>
                );
            },
        }),
        columnHelper.accessor("vehicle", {
            id: "side_number",
            header: t("side_number"),
            cell: ({ getValue }) => {
                const value = getValue();
                if (!value) return null;
                return (
                    <Link
                        to={`/vehicles/${value.id}`}
                        className="text-primary hover:underline"
                    >
                        {value.side_number}
                    </Link>
                );
            },
        }),
        columnHelper.accessor("driver", {
            id: "driver",
            header: t("driver"),
            cell: ({ getValue }) => {
                const value = getValue();
                if (!value) return null;
                return (
                    <Link
                        to={`/vehicles/${value.id}`}
                        className="text-primary hover:underline"
                    >
                        {value.name}
                    </Link>
                );
            },
        }),
        columnHelper.accessor("speed", {
            header: t("speed"),
        }),
        columnHelper.accessor(
            ({ latitude, longitude }) => ({ latitude, longitude }),
            {
                id: "coordinate",
                header: t("coordinates"),
                cell: ({ getValue }) => {
                    const { latitude, longitude } = getValue();
                    if (!+latitude! || !+longitude!) return null;
                    return (
                        <div>
                            {latitude}, {longitude}
                        </div>
                    );
                },
            },
        ),
        columnHelper.display({
            id: "action",
            cell: ({ row }) => {
                const hasVideo = row.original.video.length > 0;

                const onModalOpen = (tab: EventModalTab) => () =>
                    toggleOpen?.({
                        id: row.original.id,
                        tab,
                    });

                return (
                    <div className="flex items-center space-x-2">
                        {hasVideo && (
                            <div
                                onClick={onModalOpen(EventModalTab.Video)}
                                className="cursor-pointer text-primary hover:bg-primary/10 p-1.5 rounded-md"
                            >
                                <Video className="w-4 h-4" />
                            </div>
                        )}
                        <div
                            onClick={onModalOpen(EventModalTab.Photo)}
                            className="cursor-pointer text-primary hover:bg-primary/10 p-1.5 rounded-md"
                        >
                            <Image className="w-4 h-4" />
                        </div>
                    </div>
                );
            },
        }),
    ];
};
