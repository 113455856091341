import { useTranslate } from "@tolgee/react";
import Control from "react-leaflet-custom-control";

import { LEGEND_SPEEDS, getColor } from "../lib";

export const Legend = () => {
    const { t } = useTranslate();

    return (
        <Control position="topright">
            <div className="bg-white border-2 border-black/20 rounded-sm p-1.5">
                <div className="text-sm font-bold mb-1">{t("km_h")}</div>
                <div className="space-y-1">
                    {LEGEND_SPEEDS.map((speed) => (
                        <div key={speed} className="flex items-center gap-2">
                            <div
                                className="w-4 h-4"
                                style={{ backgroundColor: getColor(speed) }}
                            />
                            <span className="text-xs">{speed}</span>
                        </div>
                    ))}
                </div>
            </div>
        </Control>
    );
};
