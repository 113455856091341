import { useTranslate } from "@tolgee/react";
import { subMonths } from "date-fns";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { Map, Track } from "@/features";

import {
    GetDevicesByIdGpsApiArg,
    useGetDevicesByIdGpsQuery,
} from "@/shared/api/general.api";
import { useFilter } from "@/shared/lib/hooks";
import { ErrorBlock, Separator } from "@/shared/ui";

import { Filter } from "./filter";

const CURR_DATETIME = new Date();

export const GPS: React.FC = () => {
    const { t } = useTranslate();
    const { id = "" } = useParams();
    const { filter, setFilter } = useFilter<GetDevicesByIdGpsApiArg>({
        id,
        filter: {
            date_from: subMonths(CURR_DATETIME, 1).toISOString(),
            date_to: CURR_DATETIME.toISOString(),
        },
    });

    const { data, isError } = useGetDevicesByIdGpsQuery(filter, {
        skip: !id,
    });

    const onSubmit = useCallback(
        (values: GetDevicesByIdGpsApiArg) => {
            setFilter(values);
        },
        [setFilter],
    );

    return (
        <>
            <Filter values={filter} onSubmit={onSubmit} />
            <Separator className="my-8" />
            {!data || (isError && <ErrorBlock />)}
            {!data?.track.length ? (
                <ErrorBlock error={t("no_data")} />
            ) : (
                <Map>
                    {/*@ts-ignore*/}
                    <Track data={data} withPlayer />
                </Map>
            )}
        </>
    );
};
