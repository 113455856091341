// import { ChevronDown } from "lucide-react";
import { useTranslate } from "@tolgee/react";
import { subMonths } from "date-fns";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { FilterField } from "@/features";

import { GetDevicesByIdGpsApiArg } from "@/shared/api/general.api";
import { Button, DatePicker, Form } from "@/shared/ui";

const CURR_DATETIME = new Date();

export const Filter: React.FC<{
    values?: GetDevicesByIdGpsApiArg;
    onSubmit: SubmitHandler<GetDevicesByIdGpsApiArg>;
}> = ({ values, onSubmit }) => {
    const { t } = useTranslate();
    const { id = "" } = useParams();
    const form = useForm<GetDevicesByIdGpsApiArg>();

    useEffect(() => {
        form.reset(values);
    }, [form, values]);

    const onSubmitHandler: SubmitHandler<GetDevicesByIdGpsApiArg> = (data) => {
        onSubmit(data);
    };

    const onResetHandler = () => {
        onSubmit({
            id,
            filter: {
                date_from: subMonths(CURR_DATETIME, 1).toISOString(),
                date_to: CURR_DATETIME.toISOString(),
            },
        });
        form.reset({});
    };

    return (
        <Form {...form}>
            <form
                onSubmit={form.handleSubmit(onSubmitHandler)}
                className="grid gap-4"
            >
                <div className="grid grid-cols-3 gap-8">
                    <div className="grid grid-cols-2 gap-4 col-span-2">
                        <FilterField
                            name="filter.date_from"
                            label={t("form.date.from.label")}
                        >
                            <DatePicker showTimeInput />
                        </FilterField>
                        <FilterField
                            name="filter.date_to"
                            label={t("form.date.to.label")}
                        >
                            <DatePicker showTimeInput />
                        </FilterField>
                    </div>
                </div>
                <div className="flex justify-between space-x-2 mt-5">
                    <div>
                        {/*<Button type="button" variant="outline" disabled>*/}
                        {/*    {t("button.export_report")}*/}
                        {/*    <ChevronDown className="ml-1 h-4 w-4" />*/}
                        {/*</Button>*/}
                    </div>
                    <div className="flex space-x-2">
                        <Button type="submit">{t("button.apply")}</Button>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onResetHandler}
                        >
                            {t("button.reset")}
                        </Button>
                    </div>
                </div>
            </form>
        </Form>
    );
};
