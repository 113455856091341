import axios from "axios";
import {
    Centrifuge,
    PublicationContext,
    Subscription,
    UnauthorizedError,
} from "centrifuge";
import { useCallback, useEffect, useState } from "react";

import { UserResponse } from "@/shared/api/general.api";

const centrifugeUrl = import.meta.env.VITE_CENTRIFUGE_URL;
const centrifugeTokenUrl = import.meta.env.VITE_CENTRIFUGE_TOKEN_URL;

export const enum UserEventTypes {
    NOTIFICATION = ".user.notification",
    PROGRESS_BAR = ".user.progress-bar",
    DEVICE_REALTIME_VIDEO = ".user.device-realtime-video-event",
    GET_CONFIG_COMPLETE = ".user.get_config_complete",
    DEVICE_WEV_GUI = ".user.device-web-gui-events",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventCallback = (data: any) => void;

interface UseCentrifugeReturn {
    subscribeUserChannel: (
        user: UserResponse,
        event: UserEventTypes,
        fn: EventCallback,
    ) => Subscription | undefined;
    subscribePublicChannel: (
        channelName: string,
        event: string,
        fn: EventCallback,
    ) => Subscription | undefined;
}

// Custom hook for managing Centrifuge subscriptions
export const useCentrifuge = (userChannelName: string): UseCentrifugeReturn => {
    const [connector, setConnector] = useState<Centrifuge | undefined>();
    const [userChannel, setUserChannel] = useState<Subscription | undefined>();
    const [publicChannel, setPublicChannel] = useState<
        Subscription | undefined
    >();

    // Function to subscribe to user-specific channels
    const subscribeUserChannel = useCallback(
        (user: UserResponse, event: string, fn: EventCallback) => {
            if (!connector) return;

            // Replace @userId@ with user ID in channel name
            const channelName = userChannelName.replace(
                "@userId@",
                user.id.toString(),
            );

            // Return existing subscription if already subscribed to this channel
            if (userChannel?.channel === channelName) {
                return userChannel;
            }

            // Cleanup: unsubscribe from previous channel if exists
            if (userChannel) {
                userChannel.unsubscribe();
            }

            // Create new subscription with user ID in channel name
            const channel = connector.newSubscription(channelName);
            channel.on("publication", (subCtx: PublicationContext) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (subCtx.data.event === event.replace(/^\./, "")) {
                    fn(subCtx.data);
                }
            });

            channel.subscribe();
            setUserChannel(channel);
            return channel;
        },
        [connector, userChannel, userChannelName],
    );

    // Function to subscribe to public channels
    const subscribePublicChannel = useCallback(
        (channelName: string, event: string, fn: EventCallback) => {
            if (!connector) return;

            // Return existing subscription if already subscribed to this channel
            if (publicChannel?.channel === channelName) {
                return publicChannel;
            }

            // Cleanup: unsubscribe from previous channel if exists
            if (publicChannel) {
                publicChannel.unsubscribe();
            }

            // Create new subscription
            const channel = connector.newSubscription(channelName);
            channel.on("publication", (subCtx: PublicationContext) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (subCtx.data.event === event.replace(/^\./, "")) {
                    fn(subCtx.data);
                }
            });

            channel.subscribe();
            setPublicChannel(channel);
            return channel;
        },
        [connector, publicChannel],
    );

    useEffect(() => {
        // Create new Centrifuge instance with token authentication
        const centrifugeConnector = new Centrifuge(centrifugeUrl, {
            getToken: async () => {
                try {
                    const res = await axios<{ token: string }>(
                        centrifugeTokenUrl,
                    );
                    if (res.status !== 200) {
                        if (res.status === 403) {
                            throw new UnauthorizedError("Unauthorized");
                        }
                        throw new Error("Failed to get token");
                    }
                    return res.data.token;
                } catch (error) {
                    throw new Error("Failed to get token");
                }
            },
            debug: import.meta.env.DEV,
        });

        setConnector(centrifugeConnector);

        centrifugeConnector.connect();

        return () => {
            centrifugeConnector.disconnect();
        };
    }, []); // Removed 'publicChannel' and 'userChannel' from dependency array

    return {
        subscribeUserChannel,
        subscribePublicChannel,
    };
};
