import chroma from "chroma-js";
import L from "leaflet";

export const LEGEND_SPEEDS = [0, 25, 50, 75, 100];

export const getColor = (speed: number) => {
    return chroma
        .scale([
            "#00b300", // slow - green
            "#92d050", // medium-slow - light green
            "#ffcc00", // medium - yellow
            "#ff6600", // medium-fast - orange
            "#ff0000", // fast - red
        ])
        .domain(LEGEND_SPEEDS)
        .out("hex")(speed);
};

const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
};

export const getDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lng2 - lng1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in km
    return R * c;
};

export interface Dataset extends L.LatLngLiteral {
    timeIso: number;
}

export const interpolate = (
    point1: L.LatLngLiteral,
    point2: L.LatLngLiteral,
    factor: number,
): L.LatLngLiteral => {
    return {
        lat: point1.lat + (point2.lat - point1.lat) * factor,
        lng: point1.lng + (point2.lng - point1.lng) * factor,
    };
};
