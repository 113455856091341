import L from "leaflet";
import { CirclePause, PlayCircle, StopCircle } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useMap } from "react-leaflet";
import Control from "react-leaflet-custom-control";

import { formatDate } from "@/shared/lib/utils";

import { carIcon, interpolate } from "../lib";
import { IPoint } from "../model";

const enum PlayerState { // move to the first point
    Start = "start",
    Pause = "pause",
    Stop = "stop",
}

export const Player: React.FC<{ data: IPoint[] }> = ({ data }) => {
    const map = useMap();
    const [playing, setPlaying] = useState<PlayerState | null>(null);
    const [speed, setSpeed] = useState<number>(1);
    const marker = useMemo(
        () =>
            L.marker(data[0], {
                icon: carIcon,
            }),
        [data],
    );
    // const path = useMemo(
    //     () => L.polyline(data[0], { color: "red" }),
    //     [data],
    // );

    const [tick, setTick] = useState<number>(data[0].timeIso);
    const endTime = useMemo(() => data[data.length - 1].timeIso, [data]);

    const setPlayingValue = (value: PlayerState | null) => () => {
        setPlaying(value);
    };

    const setCursor = (e: React.ChangeEvent<HTMLInputElement>) => {
        map.addLayer(marker);
        setTick(parseInt(e.target.value));
        setPlayingValue(null)();
    };

    const setSpeedValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSpeed(parseInt(e.target.value));
    };

    useEffect(() => {
        if (tick > endTime) {
            setPlayingValue(PlayerState.Stop)();
            return;
        }

        let closestPointIndex = -1;
        for (let i = 0; i < data.length && closestPointIndex < 0; i += 1) {
            const timeIso = data[i].timeIso;
            if (timeIso > tick) {
                closestPointIndex = i === 0 ? 0 : i - 1;
            }
        }

        if (closestPointIndex >= 0 && closestPointIndex < data.length - 1) {
            const nextPointIndex = closestPointIndex + 1;
            const closestPoint = data[closestPointIndex];
            const nextPoint = data[nextPointIndex];
            const timeDiff = nextPoint.timeIso - closestPoint.timeIso;
            const factor = (tick - closestPoint.timeIso) / timeDiff;
            const interpolatedPoint = interpolate(
                closestPoint,
                nextPoint,
                factor,
            );

            marker.setLatLng(interpolatedPoint);
            // .bindTooltip(formatDate(new Date(value), "HH:mm:ss"), {
            //     permanent: true,
            // });
            // path.setLatLngs(
            //     data
            //         .slice(0, nextPointIndex + 1)
            //         .concat([interpolatedPoint]),
            // );
        }
        // else {
        // marker.setLatLng(data[data.length - 1]);
        // path.setLatLngs(data.slice(0, closestPointIndex + 1));
        // path.setLatLngs(data.slice(0, closestPointIndex + 1));
        // }
    }, [data, endTime, marker, tick]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (playing === PlayerState.Stop) {
            map.removeLayer(marker);
            setTick(data[0].timeIso);
            marker.setLatLng(data[0]);
            // path.setLatLngs(data[0]);
        }

        if (playing === PlayerState.Start) {
            map.addLayer(marker);
            interval = setInterval(() => {
                setTick((prev) => prev + speed * 100);
            }, 100);
        }

        return () => {
            clearInterval(interval);
            map.removeLayer(marker);
        };
    }, [data, map, marker, playing, speed]);

    return (
        <Control position="bottomleft">
            <div className="w-full flex items-center space-x-4 px-1.5 py-0.5 bg-white border-2 border-black/20 rounded-sm">
                <div className="flex space-x-2">
                    {playing === PlayerState.Start ? (
                        <button onClick={setPlayingValue(PlayerState.Pause)}>
                            <CirclePause />
                        </button>
                    ) : (
                        <button onClick={setPlayingValue(PlayerState.Start)}>
                            <PlayCircle />
                        </button>
                    )}
                    <button onClick={setPlayingValue(PlayerState.Stop)}>
                        <StopCircle />
                    </button>
                </div>
                <div className="relative w-10">
                    <select
                        name="speed"
                        id="speed"
                        onChange={setSpeedValue}
                        className="outline-none"
                    >
                        <option value="1">1x</option>
                        <option value="5">5x</option>
                        <option value="10">10x</option>
                        <option value="25">25x</option>
                        <option value="50">50x</option>
                        <option value="75">75x</option>
                    </select>
                </div>
                <div className="relative w-12">
                    {formatDate(new Date(tick), "HH:mm:ss")}
                </div>
                <div className="relative">
                    <input
                        type="range"
                        min={data[0].timeIso}
                        max={endTime}
                        list="datalist"
                        value={tick}
                        onChange={setCursor}
                        className="w-[880px]"
                    />
                    <datalist id="datalist">
                        {data.map((marker, index) => (
                            <option key={index} value={marker.timeIso} />
                        ))}
                    </datalist>
                    <div className="relative">
                        <div className="text-xs">
                            {formatDate(new Date(data[0].timeIso), "HH:mm:ss")}
                        </div>
                        <div className="text-xs absolute right-0 top-0">
                            {formatDate(new Date(endTime), "HH:mm:ss")}
                        </div>
                    </div>
                </div>
            </div>
        </Control>
    );
};
