import { memo } from "react";

import LogoDefaultSvg from "/assets/logo_1.svg";
import LogoDefaultSvg2 from "/assets/logo_2.svg";
import LogoWhiteSvg from "/assets/logo_white_1.svg";
import LogoWhiteSvg2 from "/assets/logo_white_2.svg";

interface LogoProps {
    variant?: "vertical" | "horizontal";
    type?: "primary" | "secondary";
    title?: string;
    className?: string;
}

const LOGO_MAP = {
    primary: {
        vertical: LogoDefaultSvg,
        horizontal: LogoDefaultSvg2,
    },
    secondary: {
        vertical: LogoWhiteSvg,
        horizontal: LogoWhiteSvg2,
    },
};

export const Logo = memo<LogoProps>(
    ({ variant = "vertical", type = "primary", title = "logo", className }) => {
        const imgSrc = LOGO_MAP[type]?.[variant] ?? LogoDefaultSvg;

        return (
            <div className={className}>
                <img src={imgSrc} alt={title} />
            </div>
        );
    },
);

Logo.displayName = "Logo";
